@tailwind base;
@tailwind components;
@tailwind utilities;


.card-marko {
    border: 1px solid #000;
}

.food-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 70px;
}
.food-card {
    position: relative;
    width: 43%;
    
}
.food-card img {
    /*border-radius: 10px;*/
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;
    filter: brightness(.6);
}

.food-desc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

.navbar-nav {
    background: #000 !important;
}

.logo {
    position: sticky;
    padding-left: 10px;
}

.lang {
    position: absolute;
    right: 0;
    padding-right: 10px;
}

.lang img {
    width: 20px !important;
    height: auto;
}

.logo img {
    width: 42px;
    height: auto;
}

.shrink {
    color: #CC3D00;
}

.my-8 {
    margin-top: 0;
    margin-bottom: 2rem;
}

.menu-photo {
    object-fit: cover !important;
    width: 80px !important;
    height: 80px !important;
}

.popup-nav {
    z-index: 9999 !important;
}

